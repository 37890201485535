<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <PageHeader>
          <span slot="courses" class="font-weight-light">MOUNT </span> COURSES
        </PageHeader>
        <v-card>
          <v-card-text>
            <v-form ref="form">
              <v-row justify="center">
                <v-col cols="12" lg="4">
                  <v-autocomplete
                    class="rounded-0"
                    @change="programDetails"
                    @keyup="debouncesearchProgram"
                    :loading="loadProgram"
                    v-model="program"
                    :items="itemsProgram"
                    :search-input.sync="searchProgram"
                    clearable
                    hide-details="auto"
                    hide-selected
                    item-text="program_name_certificate"
                    item-value="id"
                    label="Search Program"
                    outlined
                    :rules="[(v) => !!v || 'Program is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title v-if="loadProgram">
                          <v-progress-circular
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                          <span class="ml-5">
                            Searching
                            <strong>Program...</strong>
                          </span>
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          No
                          <strong>Record(s)...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    :menu-props="{ down: true, offsetY: true }"
                    :items="basket_level"
                    item-text="level"
                    item-value="id"
                    outlined
                    class="rounded-0"
                    label="Level"
                    hide-details="auto"
                    v-model="level"
                    hide-selected
                    :rules="[(v) => !!v || 'Level is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Levels...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" lg="4">
                  <v-select
                    class="rounded-0"
                    :menu-props="{ down: true, offsetY: true }"
                    :loading="issemesterLoaded"
                    @click="fetchSemester"
                    v-model="semester"
                    :items="basket_semester"
                    item-value="id"
                    item-text="semester"
                    outlined
                    label="Semester"
                    hide-details="auto"
                    hide-selected
                    :rules="[(v) => !!v || 'Semester is required...']"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Loading
                          <strong>Semesters...</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <p v-if="showCourses" class="text-uppercase grey--text mt-5">COURSES</p>

    <v-row v-if="showCourses">
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title primary-title class="primary white--text">
            {{
              `${
                courses.length > 0
                  ? "Total Course:" + courses.length
                  : courses.length > 1
                  ? "Total Courses:" + courses.length
                  : "No Course Available"
              }`
            }}
          </v-card-title>
          <v-divider></v-divider>
          <v-virtual-scroll
            :bench="0"
            :items="courses"
            height="350"
            item-height="64"
          >
            <template v-slot:default="{ index, item }">
              <v-list-item :key="item.id">
                <v-list-item-action>
                  {{ index + 1 }}
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>Course Name</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.coursecode_coursename }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="text-center">
                  <v-list-item-subtitle>Credit Hours</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.credit_hours }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    color="success"
                    icon
                    @click="pushToSelected(basket, item)"
                  >
                    <v-icon> mdi-arrow-right-bold-box </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card>
          <v-card-title primary-title class="primary white--text">
            {{
              `${
                basket.length > 0
                  ? "Selected Course:" + basket.length
                  : basket.length > 1
                  ? "Selected Courses:" + basket.length
                  : "No Course Selected"
              }`
            }}
          </v-card-title>
          <v-divider></v-divider>
          <v-virtual-scroll
            :bench="0"
            :items="basket"
            :height="basket.length * 50"
            item-height="64"
          >
            <template v-slot:default="{ index, item }">
              <v-list-item two-line>
                <v-list-item-action>
                  {{ index + 1 }}
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-subtitle>Course Name</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.coursecode_coursename }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-content class="text-center">
                  <v-list-item-subtitle>Credit Hours</v-list-item-subtitle>
                  <v-list-item-title>
                    {{ item.credit_hours }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    color="error"
                    icon
                    @click="spliceCourse(index, item.id)"
                  >
                    <v-icon> mdi-close-circle </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
          <v-card-actions v-if="basket.length > 0">
            <v-spacer></v-spacer>
            <v-btn color="success" @click="pclsBtn">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>

    <v-row>
      <v-col>
        <v-overlay absolute :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { getCurrentInstance, provide, reactive, ref, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";

  export default {
    components: { PageHeader, Response },
    setup() {
      const vm = getCurrentInstance()
      const {
        getHodDeptProg,
        getSemester,
        getLevel,
        getProgramDetails,
        signOut,
      } = useActions([
        "getHodDeptProg",
        "getSemester",
        "getLevel",
        "getProgramDetails",
        "signOut",
      ]);

      const {
        getters_hoddeptprog,
        getters_semester,
        getters_level,
        getters_program_details,
        getters_abilities,
      } = useGetters([
        "getters_hoddeptprog",
        "getters_semester",
        "getters_level",
        "getters_program_details",
        "getters_abilities",
      ]);

      const courseHeader = reactive({
        get_program_info: [],
        iscalendarLoaded: false,
        issemesterLoaded: false,
        islevelLoaded: false,
        isintakeLoaded: false,
        iscampusLoaded: false,
        loadedProgram: true,
        program: "",
        calendar: "",
        semester: "",
        level: "",
        intake: "",
        campus: [],
        basket_calendar: [],
        basket_semester: [],
        basket_level: [],
        basket_intake: [],
        basket_campus: [],
        ifIntake: false,
        itemsProgram: [],
        itemsGrade: [],
        loadProgram: false,
        searchProgram: "",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        response: false,
        grade: null,
        color: "",
        courses: [],
      });

      const {
        color,
        loadedProgram,
        msgHeader,
        msgBody,
        msgIcon,
        response,
        searchProgram,
        itemsProgram,
        loadProgram,
        basket_semester,
        basket_level,
        program,
        semester,
        level,
        issemesterLoaded,
        islevelLoaded,
        get_program_info,
        courses,
      } = toRefs(courseHeader);

      const mountCourseForm = reactive({
        mountLecturer: [],
        basket: [],
        loadCourse: false,
        showCourses: false,
        errorMsgs: 0,
        courseBasket: [],
        grade: null,
        form: null,
        nonCurr: [],
      });

      const absolute = ref(true);
      const overlay = ref(false);
      const courseheaderFeedback = ref(false);

      const { basket, showCourses, form, nonCurr } = toRefs(mountCourseForm);

      watch(
        () => [semester.value, level.value, program.value],
        () => {
          if (semester.value && level.value && program.value) {
            courses.value = getters_program_details.value[0].courses;

            Api()
              .post("/getpcls", {
                semester_id: semester.value,
                level_id: level.value,
                program_id: program.value,
              })
              .then((res) => {
                showCourses.value = true;
                overlay.value = false;

                basket.value = res.data.data.map((item) => ({
                  ...item.course,
                }));

                console.log(basket.value);
              })
              .catch((e) => {
                response.value = true;
                msgIcon.value = "mdi-close-circle";
                msgHeader.value = "Error";
                color.value = "error";
                overlay.value = false;
                showCourses.value = false;
                courseheaderFeedback.value = true;
                switch (e.response.status) {
                  case 403:
                    msgBody.value = e.response.data.message;
                    break;

                  case 404:
                    msgBody.value = e.response.data.message;
                    break;

                  case 422:
                    msgBody.value = e.response.data.message;
                    break;

                  default:
                    msgBody.value =
                      "Try Again Later Or Call The System Administrator";
                    break;
                }
              });
          }
        }
      );

      provide("color", color);

      const debouncesearchProgram = debounce(() => {
        if (searchProgram.value != null || searchProgram.value != "undefined") {
          if (itemsProgram.value.length > 0) return;
          loadProgram.value = true;
          getHodDeptProg()
            .then(() => {
              itemsProgram.value = getters_hoddeptprog.value;
            })
            .finally(() => (loadProgram.value = false));
        }
      });

      const programDetails = (i) => {
        getProgramDetails(i).finally(() => {
          if (!i) return;
          islevelLoaded.value = true;
          loadedProgram.value = true;
          level.value = "";
          get_program_info.value = getters_hoddeptprog.value.find(
            (item) => item.id === i
          );

          getLevel().then(() => {
            //basket_level.value = getters_level.value;
            if (get_program_info.value.program_state) {
              basket_level.value = getters_level.value.filter(
                (item) => item.level >= 300
              );
            } else {
              basket_level.value = getters_level.value;
            }
            islevelLoaded.value = false;
          });

          loadedProgram.value = false;
        });
      };

      const fetchSemester = () => {
        issemesterLoaded.value = true;
        if (getters_semester.value.length > 0) {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
          return;
        }

        getSemester().then(() => {
          basket_semester.value = getters_semester.value;
          issemesterLoaded.value = false;
        });
      };

      const spliceCourse = (i, id) => {
        basket.value.splice(i, 1);
        nonCurr.value.push(id);
      };

      const pclsBtn = async () => {
        overlay.value = true;
        response.value = false;
        await Api()
          .post("/savepcls", {
            // program_id: program.value,
            current_courses: basket.value.map((item) => ({
              course_id: item.id,
              level_id: level.value,
              semester_id: semester.value,
              program_id: program.value,
            })),
            //level_id: level.value,
            // semester_id: semester.value,
            non_current_courses: nonCurr.value.map((id) => ({
              course_id: id,
              level_id: null,
              semester_id: null,
              program_id: program.value,
            })),
          })
          .then(() => {
            form.value.reset();

            showCourses.value = false;
            color.value = "success";
            msgHeader.value = "Success";
            msgIcon.value = "mdi-close-circle";
            msgBody.value = "The Program Course Are Successfully Mounted...";
          })
          .catch((e) => {
            overlay.value = false;
            response.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            switch (e.response.status) {
              case 403:
                msgBody.value = "THIS ACTION IS UNAUTHORIZED";
                break;

              case 423:
                msgBody.value = e.response.data.message;
                signOut().then(() => {
                  vm.proxy.$router.replace({
                    name: "Login",
                  });
                });
                break;

              default:
                msgBody.value =
                  "Try Again Later Or Call The System Administrator";
                break;
            }
          })
          .finally(() => {
            overlay.value = false;
            response.value = true;
          });
      };

      const pushItems = (x, y) => {
        let bool = x.some((item) => item.id === y.id);
        if (bool) {
          setTimeout(() => {
            response.value = true;
            msgHeader.value = "Error";
            msgBody.value = "Course Already Exists";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
          });
        } else {
          x.push(y);
        }
      };

      const pushToSelected = (courses, course) => {
        response.value = false;
        pushItems(courses, course);
        nonCurr.value.filter((item) => item === course.id);
      };

      return {
        debouncesearchProgram,
        ...toRefs(mountCourseForm),
        ...toRefs(courseHeader),
        programDetails,
        fetchSemester,
        spliceCourse,
        pclsBtn,
        absolute,
        overlay,
        courseheaderFeedback,
        getters_abilities,
        pushToSelected,
      };
    },
  };
</script>
